<template>
  <div class="TopCreatorsList">
    <div class="TopCreatorsListTitle sec-font" tcenter>{{ $locale["meet_our_top_creators"] }}</div>
    <div class="TopCreatorsListContent">
      <router-link class="creator" v-for="(creator, index) in topCreators" :key="index" :to="`/${creator.user}`">
        <TopCreatorCard :creator="creator" :index="index" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    TopCreatorCard: () => import("../landing/TopCreatorCard"),
  },
  data: function() {
    return {
      limit: 30,
      loading: true,
    };
  },
  methods: {
    getCreators: async function({ page = 1 } = {}) {
      if (this.topCreators && this.topCreators.length) {
        this.loading = false;
        return;
      }

      try {
        this.query = `page=${page}`;
        this.query += `&limit=${this.limit}`;
        this.req = await this.$api.get(`${process.env.VUE_APP_API_HOST}creator/getTopCreators?${this.query}`);
        this.$store.dispatch("setSearchTopCreators", this.req.data);
      } catch (error) {
        this.error = error;
        console.error(error);
      }

      this.loading = false;
      return;
    },
  },
  mounted: function() {
    this.getCreators();
  },
  computed: {
    topCreators: function() {
      return this.$store.state.search.topCreators && this.$store.state.search.topCreators.items;
    },
  },
};
</script>

<style lang="scss">
.TopCreatorsList {
  .TopCreatorsListContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $mpadding/2;
    padding: $mpadding/1.5;
    .creator {
      margin: 0 0 $mpadding 0;
      cursor: pointer;
      &-picture {
        display: block;
        width: 100%;
        height: 200px;
        background-image: var(--picture);
        background-size: cover;
        background-position: top 10% center;
        margin: 0 0 $mpadding/2 0;
        border-radius: $mradius;
      }
      &-name {
        font-family: $sec-font;
        font-size: 150%;
        color: $emperor;
      }
      &-label {
        color: $emperor;
      }
    }
  }

  .TopCreatorsListTitle {
    padding: $mpadding $mpadding/2;
    border-bottom: solid 1px $alto;
    background-color: #fff;
  }

  @media (min-width: $tablet-width) {
    .TopCreatorsListContent {
      gap: $mpadding;
      grid-template-columns: repeat(3, 1fr);
      .creator {
        &-picture {
          height: 240px;
        }
      }
    }
  }

  @media (min-width: $pc-width) {
    .TopCreatorsListContent {
      grid-template-columns: repeat(6, 1fr);
      .creator {
        margin: 0 0 0 0;
        &-picture {
          height: 240px;
        }
      }
    }
  }
}
</style>
